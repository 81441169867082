var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Card from 'shared/components/Card/Card';
import Table from 'shared/components/Table/Table';
import cls from './UsersScreen.module.css';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { Button, ButtonVariants } from 'shared/components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BannerTypes, useBannerContext, } from 'app/providers/BannerProvider/banner-context';
import { UserRoles } from 'app/interfaces/User';
import EditableText from 'shared/components/EditableText/EditableText';
import EditableSelect from 'shared/components/EditableSelect/EditableSelect';
import InviteUserModal from 'widgets/InviteUserModal/InviteUserModal';
import classNames from 'classnames';
import UsersScreenMobileTable from './UsersMobileTable/UsersMobileTable';
import { DeleteUserButton } from './components/DeleteUserButton';
import SelectInput from 'shared/components/SelectInput/SelectInput';
import Help from 'shared/assets/icons/help.svg';
import { Tooltip } from 'antd';
var tableHeaders = [
    'First Name',
    'Last Name',
    _jsxs("div", __assign({ className: "header_tooltip" }, { children: ["Email", ' ', _jsx(Tooltip, __assign({ title: _jsxs("div", __assign({ className: "tooltip_content" }, { children: [_jsx("h4", { children: "Email" }), _jsx("p", { children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, impedit!" })] })), overlayInnerStyle: { width: '250px' }, color: "#fff", placement: "top" }, { children: _jsx(Help, {}) }))] }), 'users_header_email'),
    _jsxs("div", __assign({ className: "header_tooltip" }, { children: ["Role", ' ', _jsx(Tooltip, __assign({ title: _jsxs("div", __assign({ className: "tooltip_content" }, { children: [_jsx("h4", { children: "Role" }), _jsx("p", { children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, impedit!" })] })), overlayInnerStyle: { width: '250px' }, color: "#fff", placement: "top" }, { children: _jsx(Help, {}) }))] }), 'users_header_email'),
    _jsxs("div", __assign({ className: "header_tooltip" }, { children: ["Invoices", ' ', _jsx(Tooltip, __assign({ title: _jsxs("div", __assign({ className: "tooltip_content" }, { children: [_jsx("h4", { children: "Invoices" }), _jsx("p", { children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, impedit!" })] })), overlayInnerStyle: { width: '250px' }, color: "#fff", placement: "top" }, { children: _jsx(Help, {}) }))] }), 'users_header_email'),
    _jsxs("div", __assign({ className: "header_tooltip" }, { children: ["Status", ' ', _jsx(Tooltip, __assign({ title: _jsxs("div", __assign({ className: "tooltip_content" }, { children: [_jsx("h4", { children: "Status" }), _jsx("p", { children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, impedit!" })] })), overlayInnerStyle: { width: '250px' }, color: "#fff", placement: "top" }, { children: _jsx(Help, {}) }))] }), 'users_header_email'),
    'Actions',
];
var USERS_BY_ORGANIZATION_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query UsersByOrganization($organizationId: String!) {\n        usersByOrganization(organizationId: $organizationId) {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            role\n            invoices\n            active\n        }\n    }\n"], ["\n    query UsersByOrganization($organizationId: String!) {\n        usersByOrganization(organizationId: $organizationId) {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            role\n            invoices\n            active\n        }\n    }\n"])));
var UPDATE_USER_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {\n        updateUser(userId: $userId, input: $input) {\n            id\n        }\n    }\n"], ["\n    mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {\n        updateUser(userId: $userId, input: $input) {\n            id\n        }\n    }\n"])));
var UsersScreen = function () {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var user = useUserContext().user;
    var show = useBannerContext().show;
    var _c = useState(false), isEditing = _c[0], setIsEditing = _c[1];
    var _d = useState({}), editValues = _d[0], setEditValues = _d[1];
    var _e = useState(false), showInviteModal = _e[0], setShowInviteModal = _e[1];
    var usersQuery = useQuery(USERS_BY_ORGANIZATION_QUERY, {
        variables: {
            organizationId: user === null || user === void 0 ? void 0 : user.organization.id,
        },
        skip: !user,
    });
    var updateUserMutation = useMutation(UPDATE_USER_MUTATION)[0];
    var toggleEdit = function () {
        setIsEditing(!isEditing);
    };
    var onChange = function (id, field, value) {
        var _a, _b;
        setEditValues(__assign(__assign({}, editValues), (_a = {}, _a[id] = __assign(__assign({}, editValues[id]), (_b = {}, _b[field] = field === 'invoices' ? value === 'yes' : value, _b)), _a)));
    };
    var onSaveClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, Promise.all(Object.keys(editValues).map(function (id) {
                            return updateUserMutation({
                                variables: {
                                    userId: id,
                                    input: __assign({}, editValues[id]),
                                },
                            });
                        }))];
                case 1:
                    _a.sent();
                    show('User(s) updated successfully', BannerTypes.SUCCESS);
                    return [4 /*yield*/, usersQuery.refetch()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    show(e_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a, _b;
        if ((_a = usersQuery.data) === null || _a === void 0 ? void 0 : _a.usersByOrganization) {
            var values_1 = {};
            (_b = usersQuery.data) === null || _b === void 0 ? void 0 : _b.usersByOrganization.forEach(function (_a) {
                var firstName = _a.firstName, lastName = _a.lastName, invoices = _a.invoices, id = _a.id, role = _a.role;
                values_1[id] = {
                    firstName: firstName,
                    lastName: lastName,
                    invoices: invoices,
                    role: role,
                };
            });
            setEditValues(values_1);
        }
    }, [usersQuery, isEditing]);
    useEffect(function () {
        if (!usersQuery.loading) {
            if (usersQuery.error) {
                show(usersQuery.error.message);
            }
            else if (usersQuery.data) {
                setData(usersQuery.data.usersByOrganization.map(function (currentUser) {
                    var _a, _b, _c, _d, _e;
                    var userId = currentUser.id, firstName = currentUser.firstName, lastName = currentUser.lastName, email = currentUser.email, active = currentUser.active, role = currentUser.role;
                    var rolesSelectOptions = [];
                    if ((user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN) {
                        rolesSelectOptions.push({
                            value: UserRoles.SUPER_ADMIN,
                            label: 'Super Admin',
                        });
                        if ((user === null || user === void 0 ? void 0 : user.id) !== userId) {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                            rolesSelectOptions.push({
                                value: UserRoles.USER,
                                label: 'User',
                            });
                        }
                    }
                    else {
                        if (role === UserRoles.SUPER_ADMIN) {
                            rolesSelectOptions.push({
                                value: UserRoles.SUPER_ADMIN,
                                label: 'Super Admin',
                            });
                        }
                        else if ((user === null || user === void 0 ? void 0 : user.id) === userId) {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                        }
                        else {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                            rolesSelectOptions.push({
                                value: UserRoles.USER,
                                label: 'User',
                            });
                        }
                    }
                    return [
                        _jsx("div", __assign({ className: cls.full_name }, { children: isEditing ? (_jsx("div", __assign({ className: cls.full_name_edit }, { children: _jsx(EditableText, { id: "firstName", inputClassName: classNames(cls.textInput, cls.inputWrapper), edit: isEditing, value: (_a = editValues[userId]) === null || _a === void 0 ? void 0 : _a.firstName, onChange: function (field, value) {
                                        return onChange(userId, field, value);
                                    } }) }))) : (_jsx("span", { children: firstName })) }), "1"),
                        _jsx("div", __assign({ className: cls.full_name }, { children: isEditing ? (_jsx("div", __assign({ className: cls.full_name_edit }, { children: _jsx(EditableText, { id: "lastName", inputClassName: classNames(cls.textInput, cls.inputWrapper), edit: isEditing, value: (_b = editValues[userId]) === null || _b === void 0 ? void 0 : _b.lastName, onChange: function (id, value) {
                                        return onChange(userId, id, value);
                                    } }) }))) : (_jsx("span", { children: lastName })) }), "2"),
                        _jsx("span", __assign({ className: cls.email }, { children: email }), "3"),
                        _jsx("div", __assign({ className: cls.role }, { children: isEditing ? (_jsx(EditableSelect, { id: "role", value: (_c = editValues[userId]) === null || _c === void 0 ? void 0 : _c.role, edit: isEditing, inputClassName: classNames(cls.inputWrapper, cls.selectInput, 'user_select_input'), onChange: function (id, value) {
                                    return onChange(userId, id, value);
                                }, options: rolesSelectOptions })) : (_jsx("span", __assign({ className: (user === null || user === void 0 ? void 0 : user.role) === UserRoles.USER
                                    ? "".concat(cls.badge_transparent, " ").concat(cls.badge)
                                    : "".concat(cls.badge) }, { children: (_d = editValues[userId]) === null || _d === void 0 ? void 0 : _d.role }))) }), "4"),
                        _jsx(EditableSelect, { id: "invoices", value: ((_e = editValues[userId]) === null || _e === void 0 ? void 0 : _e.invoices) ? 'yes' : 'no', edit: isEditing, onChange: function (key, value) {
                                return onChange(userId, key, value);
                            }, options: [
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                            ], inputClassName: classNames(cls.inputWrapper, cls.selectInput, 'user_select_input') }, "5"),
                        _jsx("div", __assign({ className: active ? cls.active : cls.inactive }, { children: active ? 'Active' : 'Inactive' }), 6),
                        _jsx(DeleteUserButton, { user: currentUser }, "delete-user-button"),
                    ];
                }));
                setLoading(false);
            }
        }
    }, [usersQuery, isEditing, editValues]);
    if (!user)
        return null;
    var UserTableHeader = function () {
        return (_jsxs("div", __assign({ className: cls.cardHeader }, { children: [_jsxs("h4", __assign({ className: cls.account_info }, { children: [_jsx("span", { children: "Account" }), " ", user.organization.name] })), isEditing ? (_jsxs("div", __assign({ className: cls.editButtons }, { children: [_jsx(Button, { text: "Cancel", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.cancelButton, onClick: toggleEdit }), _jsx(Button, { text: "Save", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.editButton, onClick: onSaveClick })] }))) : (_jsx(Button, { text: "Edit", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.editButton, onClick: toggleEdit }))] })));
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: cls.pageHeader }, { children: [_jsx("h1", { children: "Managers" }), _jsx(Button, { text: "Invite", variant: ButtonVariants.ACTION_BLUE, type: "button", classNamesProps: cls.inviteButton, onClick: function () { return setShowInviteModal(true); } })] })), _jsx("div", __assign({ className: "for_mob" }, { children: _jsx(SelectInput, { className: "".concat(cls.select), size: "xs", options: [
                        { value: 'All Accounts', label: 'All Accounts' },
                        {
                            value: 'Default Account',
                            label: 'Default Account',
                        },
                    ], value: 'All Accounts' }) })), _jsx("div", __assign({ className: "for_des" }, { children: _jsxs(Card, __assign({ className: cls.usersCard, id: "users-card" }, { children: [_jsx(UserTableHeader, {}), _jsx(Table, { headers: tableHeaders, data: data, loading: loading, noDataLabel: "No Managers", pagination: true, className: classNames(cls.users_table, isEditing && cls.users_edit_table) })] })) })), _jsxs("div", __assign({ className: "for_mob" }, { children: [_jsx(UserTableHeader, {}), _jsx(UsersScreenMobileTable, { isEditing: isEditing, data: usersQuery, onChange: onChange, values: editValues })] })), _jsx(InviteUserModal, { organizationName: user.organization.name, organizationId: user.organization.id, inviterId: user.id, open: showInviteModal, onClose: function () { return setShowInviteModal(false); }, refetchUsers: usersQuery.refetch })] }));
};
export default UsersScreen;
var templateObject_1, templateObject_2;
