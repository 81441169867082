var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, useEffect, useState } from 'react';
import cls from './SideBar.module.css';
import SideBarLogo from 'shared/assets/icons/SideLogo.svg';
import { RoutePaths } from 'app/providers/AppRouter';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from 'shared/assets/icons/Dashboard.svg';
import Profile from 'shared/assets/icons/profile.svg';
import LicensesIcon from 'shared/assets/icons/licenses.svg';
import Logout from 'shared/assets/icons/logout.svg';
import UsersIcon from 'shared/assets/icons/users-icon.svg';
import AccountsIcon from 'shared/assets/icons/accounts-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { useBannerContext } from 'app/providers/BannerProvider/banner-context';
import { UserRoles } from 'app/interfaces/User';
import { Dropdown, Space, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Text, TextVariants } from 'shared/components';
import { DownOutlined } from '@ant-design/icons';
import Avatar from 'antd/es/avatar/avatar';
import classNames from 'classnames';
var ALLOWED_ROLES = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN];
var USERS_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query GetUsers {\n        activeUsers {\n            id\n            firebaseUid\n            firstName\n            lastName\n            organization {\n                id\n                name\n            }\n        }\n    }\n"], ["\n    query GetUsers {\n        activeUsers {\n            id\n            firebaseUid\n            firstName\n            lastName\n            organization {\n                id\n                name\n            }\n        }\n    }\n"])));
var PROXY_LOGIN_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation ProxyLogin($firebaseUid: String!) {\n        proxyLogin(firebaseUid: $firebaseUid)\n    }\n"], ["\n    mutation ProxyLogin($firebaseUid: String!) {\n        proxyLogin(firebaseUid: $firebaseUid)\n    }\n"])));
export var SideBar = memo(function (_a) {
    var menuShow = _a.menuShow, setMenuShow = _a.setMenuShow;
    var navigate = useNavigate();
    var location = useLocation();
    var user = useUserContext().user;
    var logout = useAuthContext().logout;
    var show = useBannerContext().show;
    var proxyLogin = useAuthContext().proxyLogin;
    var _b = useState([{ label: 'Loading...', key: '1', disabled: true }]), proxyLoginOptions = _b[0], setProxyLoginOptions = _b[1];
    var handleLogout = function () {
        logout();
        navigate(RoutePaths.LOGIN);
    };
    var usersQuery = useQuery(USERS_QUERY, {
        skip: (user === null || user === void 0 ? void 0 : user.role) !== UserRoles.SUPER_ADMIN,
    });
    var proxyLoginMutation = useMutation(PROXY_LOGIN_MUTATION)[0];
    useEffect(function () {
        if (!usersQuery.loading) {
            if (usersQuery.error) {
                show('Error fetching users');
            }
            else if (usersQuery.data) {
                setProxyLoginOptions(usersQuery.data.activeUsers.map(function (user) {
                    var _a;
                    return ({
                        label: "".concat(user.firstName, " ").concat(user.lastName, " - ").concat((_a = user.organization) === null || _a === void 0 ? void 0 : _a.name),
                        key: user.firebaseUid,
                    });
                }));
            }
        }
    }, [usersQuery]);
    var onProxyLoginClick = function (_a) {
        var key = _a.key;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b, data, errors, error;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, proxyLoginMutation({
                            variables: { firebaseUid: key },
                        })];
                    case 1:
                        _b = _c.sent(), data = _b.data, errors = _b.errors;
                        if (!(data === null || data === void 0 ? void 0 : data.proxyLogin)) return [3 /*break*/, 3];
                        return [4 /*yield*/, proxyLogin(data === null || data === void 0 ? void 0 : data.proxyLogin)];
                    case 2:
                        error = _c.sent();
                        if (error) {
                            show(error);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        show(JSON.stringify(errors));
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var isAdmin = useMemo(function () { return user && ALLOWED_ROLES.includes(user.role); }, [user]);
    if (!user)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "".concat(cls.SideBar, " sidebar") }, { children: [_jsxs("div", __assign({ className: cls.logocontainer }, { children: [_jsx(Link, __assign({ className: cls.logo, to: RoutePaths.DASHBOARD }, { children: _jsx(SideBarLogo, {}) })), _jsxs("button", __assign({ type: "button", className: cls.close_btn, onClick: function () { return setMenuShow(false); } }, { children: [_jsx("span", {}), _jsx("span", {})] }))] })), _jsxs("div", __assign({ className: cls.navigationcontainer }, { children: [_jsxs("div", __assign({ className: cls.profileMenu }, { children: [_jsx(Avatar, __assign({ className: cls.avatar, alt: "user avatar", size: 46, onClick: function () { return navigate('/profile'); } }, { children: user && user.firstName.charAt(0).toUpperCase() })), _jsxs("div", __assign({ className: cls.avatar_info }, { children: [_jsx(Text, __assign({ tag: "p", classNamesProps: cls.usertitle, variant: TextVariants.PARAGRAPH_TITLE }, { children: user &&
                                                    "".concat(user.firstName, " ").concat(user.lastName) })), (user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN ? (_jsx(_Fragment, { children: _jsx(Dropdown, __assign({ menu: {
                                                        items: proxyLoginOptions,
                                                        onClick: onProxyLoginClick,
                                                        selectable: true,
                                                        defaultSelectedKeys: ['3'],
                                                    }, className: cls.proxy_login, rootClassName: cls.proxy_login_dropdown }, { children: _jsx(Typography.Link, { children: _jsxs(Space, __assign({ size: 5 }, { children: ["All Users", _jsx(DownOutlined, { size: 5 })] })) }) })) })) : (_jsx(Typography.Link, { children: _jsx(Space, __assign({ size: 5 }, { children: user === null || user === void 0 ? void 0 : user.role })) }))] }))] })), _jsxs(Link, __assign({ className: classNames(cls.navitem, location.pathname === RoutePaths.DASHBOARD &&
                                    cls.active), to: RoutePaths.DASHBOARD, onClick: function () { return setMenuShow(false); } }, { children: [_jsx(DashboardIcon, { className: cls.navicon }), " Dashboard"] })), _jsxs(Link, __assign({ className: classNames(cls.navitem, location.pathname === RoutePaths.PROFILE &&
                                    cls.active), to: RoutePaths.PROFILE, onClick: function () { return setMenuShow(false); } }, { children: [_jsx(Profile, { className: cls.navicon }), " Profile"] })), isAdmin ? (_jsxs(Link, __assign({ className: classNames(cls.navitem, location.pathname === RoutePaths.USERS &&
                                    cls.active), to: RoutePaths.USERS, onClick: function () { return setMenuShow(false); } }, { children: [_jsx(UsersIcon, { className: cls.navicon }), " Managers"] }))) : null, isAdmin ? (_jsxs(Link, __assign({ className: classNames(cls.navitem, location.pathname === RoutePaths.LICENSES &&
                                    cls.active), to: RoutePaths.LICENSES, onClick: function () { return setMenuShow(false); } }, { children: [_jsx(LicensesIcon, { className: cls.navicon }), ' ', "Licenses"] }))) : null, _jsxs(Link, __assign({ className: classNames(cls.navitem, location.pathname === RoutePaths.ACCOUNTS &&
                                    cls.active), to: RoutePaths.ACCOUNTS, onClick: function () { return setMenuShow(false); } }, { children: [_jsx(AccountsIcon, { className: cls.navicon }), " Accounts"] }))] })), _jsxs("div", __assign({ className: cls.navifooter }, { children: [_jsxs("button", __assign({ id: "logout", className: cls.navitem, onClick: handleLogout }, { children: [_jsx(Logout, { className: cls.navicon }), " Log Out"] })), _jsx("div", { className: cls.divider }), _jsxs("div", __assign({ className: cls.sidebar_copyright }, { children: [_jsx("div", __assign({ className: cls.menu }, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/privacy/', title: "Privacy", target: "_blank" }, { children: "Privacy" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/terms/', title: "Terms", target: "_blank" }, { children: "Terms" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/ethics/', title: "Ethics", target: "_blank" }, { children: "Ethics" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/request-support/', title: "Support", target: "_blank" }, { children: "Support" })) })] }) })), _jsx("p", __assign({ className: "".concat(cls.copyrights, " ").concat(cls.copyrights_mobile) }, { children: "\u00A9 Copyrights 2024. Tomato.ai" }))] }))] }))] })), _jsx("div", { className: classNames(cls.sidebar_overlay, menuShow && cls.sidebar_overlay_open), onClick: function () { return setMenuShow(false); } })] }));
});
SideBar.displayName = 'SideBar';
var templateObject_1, templateObject_2;
