var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { signOut } from 'firebase/auth';
import { auth } from 'firebase/firebase';
function createApolloClient(token) {
    var httpLink = createHttpLink({
        uri: process.env.GRAPHQL_URI,
        credentials: 'include',
    });
    var authLink = setContext(function (_, _a) {
        var headers = _a.headers;
        return ({
            headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : '' }),
        });
    });
    var signOutLink = new ApolloLink(function (operation, forward) {
        return forward(operation).map(function (response) {
            var _a;
            if ((_a = response.errors) === null || _a === void 0 ? void 0 : _a.some(function (error) {
                var _a;
                var ext = error.extensions;
                return ((_a = ext.originalError) === null || _a === void 0 ? void 0 : _a.statusCode) === 403;
            })) {
                signOut(auth);
            }
            return response;
        });
    });
    var client = new ApolloClient({
        link: ApolloLink.from([signOutLink, authLink, httpLink]),
        cache: new InMemoryCache(),
    });
    return client;
}
export { createApolloClient };
